class Configuration {
    
    // RIVKIN_API_URL = "http://localhost:3000/staging/v1/"; 
    RIVKIN_API_URL = "https://staging-api-users.rivkin.com.au/v1/";
    // RIVKIN_API_URL = "https://api-users.rivkin.com.au/v1/";
    
    // RIVKIN_TT_API_URL = "http://localhost:3000/staging/v1/";
    RIVKIN_TT_API_URL = "https://staging-api-trading.rivkin.com.au/v1/"; 
    // RIVKIN_TT_API_URL = "https://api-trading.rivkin.com.au/v1/"; 
    
}
export default Configuration;
